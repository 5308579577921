<template>
  <span
    @click.stop.prevent="onClick"
    :class="busy ? 'busy' : isValid && isEnabled ? 'enabled' : 'disabled'"
    :title="title"
  >
    <i v-if="busy" class="fa fa-refresh fa-spin"></i>
    <i v-else :class="`fa fa-toggle-${isEnabled ? 'on' : 'off'} `"></i>
    <span v-if="!busy">
      {{ $t(isEnabled ? "yes" : "no") }}
    </span>
  </span>
</template>

<script>
export default {
  name: "ResourceToggle",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    busy: false,
    warned: false
  }),
  computed: {
    isEnabled() {
      return this.item.enabled;
    },
    title() {
      return this.busy
        ? this.$t("please_wait")
        : this.$tc("synoptic.events.change", 2);
    },
    isValid() {
      return (
        this.item && this.item.id && "enabled" in this.item && this.modelAPI
      );
    }
  },
  methods: {
    onClick() {
      if (this.busy || !this.isValid) return;
      if (!this.isEnabled || this.warned) {
        this.save();
      } else {
        this.confirm(
          this.$t("are_you_sure"),
          this.$t("hints.disabling_resource")
        ).then(() => {
          this.warned = true;
          this.save();
        });
      }
    },
    async save() {
      const model = this.modelAPI();
      const _save = () => {
        let payload = {
          id: this.item.id,
          etag: this.item.etag,
          enabled: !this.item.enabled
        };
        model.service.save(payload).then((resp) => {
          this.busy = false;
          if (Array.isArray(resp) && resp.length) {
            resp = resp[0];
          }
          if (model.validator) {
            if (model.validator(resp)) {
              this.item.enabled = resp.enabled;
              this.item.etag = resp.etag;
              if (model.commiter) this.$store.commit(model.commiter, resp);
            }
            model.alert();
          } else {
            if (resp.etag) {
              this.item.enabled = resp.enabled;
              this.item.etag = resp.etag;
              if (model.commiter) this.$store.commit(model.commiter, resp);
            }
          }
        });
      };
      if (this.item.etag) {
        this.busy = true;
        _save();
      } else {
        this.busy = true;
        model.service.get(this.item.id).then((resp) => {
          if (resp && resp.etag) {
            this.item.etag = resp.etag;
            _save();
          } else {
            this.busy = false;
            // TODO: show error message;
          }
        });
      }
    },
    modelAPI() {
      return this.item && this?.$parent?.$parent?.service
        ? {
            type: this.$parent.$parent.$data.entity,
            service: this.$parent.$parent.service,
            validator: this.$parent.$parent.validateSaveResponse,
            alert: this.$parent.$parent.showAlert,
            commiter: {
              alarm: "dashboard/SET_ALARM_VALUE",
              data: "dashboard/SET_DATA_VALUE",
              device: "dashboard/SET_DEVICE_VALUE",
              connector: "dashboard/SET_CONNECTOR_VALUE"
            }[this.$parent.$parent.$data.entity]
          }
        : null;
    },
    confirm(title, msg) {
      return new Promise((resolve) => {
        let wrapper = document.createElement("div");
        wrapper.innerHTML = `${msg}<br><span class="text-warning"><i class='fa fa-exclamation-triangle'></i> ${this.$t(
          "hints.disabled_resources"
        )}</span>`;
        this.$swal({
          title: title,
          content: wrapper,
          buttons: [this.$t("cancel"), this.$t("confirm")]
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(true);
            return;
          }
          resolve(false);
        });
      });
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.busy:hover {
  cursor: wait;
  color: gray;
  opacity: 0.8;
}

.enabled {
  color: #31708f;
}
.disabled {
  color: gray;
}
</style>
